export function getTenantFromCurrentUrl(config) {
  const { hostname } = useRequestURL();
  const rootDomains = config.public.rootDomains || [];
  const rootDomain = rootDomains.find((domain) => hostname.endsWith(domain));
  if (!rootDomain) {
    return "";
  }
  const idx = hostname.indexOf(rootDomain);
  const tenant = hostname.substring(0, idx - 1);
  return tenant;
}
