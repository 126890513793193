<template>
  <Toast :pt="{
    icon: ({ props: toastProps }) => ({
      class: [
        {
          'is-success': toastProps.message?.severity === 'success',
          'is-error': toastProps.message?.severity === 'error',
        },
      ],
    }),
  }" @close="visible = false" :group="group">
    <template #icon="{ class: className }">
      <i-check-circle v-if="className?.includes('is-success')" aria-hidden="true" class="h-6 w-6 text-green-400" />
      <i-exclamation-circle v-if="className?.includes('is-error')" class="h-6 w-6 text-red-500" />
    </template>
  </Toast>
</template>
<script setup>
import Toast from 'primevue/toast';

const visible = ref(false)
const { $eventBus } = useNuxtApp()
const toastManager = useToast();
const group = ref(null)
$eventBus.on('toast', (obj) => {
  if (obj.group && obj.unique) {
    toastManager.removeGroup(obj.group);
  }
  group.value = obj?.group ?? null;
  obj.life = obj?.life ?? 2000
  toastManager.add(obj)
  visible.value = true
})
</script>
