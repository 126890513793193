enum Permissions {
  AccessToGeneralSetting = "access_to_general_setting",
  AccessToInvoicingSetting = "access_to_invoicing_setting",
  AccessToOthersSetting = "access_to_others_setting",
  AccessToHomeSettingPage = "access_to_home_setting_page",
  CreateCity = "create_city",
  EditCity = "edit_city",
  DeleteCity = "delete_city",
  ActivateCity = "activate_city",
  CityPeriodInvoicing = "city_period_invoicing",
  EditMarket = "edit_market",
  DeleteMarket = "delete_market",
  ActivateMarket = "activate_market",
  LinkSubscriptionToMarket = "link_subscription_to_market",
  LinkPeriodInvoicingMarket = "link_period_invoicing_market",
  EditMarketMarking = "edit_market_marking",
  DeleteMarketMaking = "delete_market_making",
  LinkVolantMarketMaking = "link_volant_market_making",
  LinkInvoiceMarketMaking = "link_invoice_market_making",
  EditStatusMarketMaking = "edit_status_market_making",
  CreateMarketMaking = "create_market_making",
  DeleteHisAccount = "delete_his_account",
  DeleteAccount = "delete_account",
  CreateSubscription = "create_subscription",
  CreateEnterprise = "create_enterprise",
  EditEnterprise = "edit_enterprise",
  DeleteEnterprise = "delete_enterprise",
  EditMerchant = "edit_merchant",
  ViewUser = "view_user",
  ViewMarketMaking = "view_market_making",
  ViewUsers = "view_users",
  ViewCity = "view_city",
  ViewEnterprise = "view_enterprise",
  ViewPlacer = "view_placer",
  ViewMarket = "view_market",
  ViewMarkets = "view_markets",
  ViewMarketMakings = "view_market_makings",
  ViewMerchants = "view_merchants",
  ViewMerchant = "view_merchant",
  CreateCompany = "create_company",
  ViewSubscription = "view_subscription",
  ViewSubscriptions = "view_subscriptions",
  EditPlacer = "edit_placer",
  ViewProduct = "view_product",
  CreateProduct = "create_product",
  ViewProducts = "view_products",
  EditProduct = "edit_product",
  DeleteProduct = "delete_product",
  ViewInvoice = "view_invoice",
  ViewInvoicies = "view_invoicies",
  CreateInvoice = "create_invoice",
  EditInvoice = "edit_invoice",
  DeleteInvoice = "delete_invoice",
  CreateCredit = "create_credit",
  ViewPayment = "view_payment",
  ViewPayments = "view_payments",
  CreatePayment = "create_payment",
  EditPayment = "edit_payment",
  DeletePayment = "delete_payment",
  ViewCompany = "view_company",
  ViewInvoices = "view_invoices",
  ViewDeposit = "view_deposit",
  ViewDeposits = "view_deposits",
  CreateDeposit = "create_deposit",
  EditDeposit = "edit_deposit",
  DeleteDeposit = "delete_deposit",
  ExportDeposit = "export_deposit",
  CreateActivityHistory = "create_activity_history",
  CreateContact = "create_contact",
  CreateComment = "create_comment",
  ViewContact = "view_contact",
  ViewEvent = "view_event",
  ViewEvents = "view_events",
  CreateEvent = "create_event",
  EditEvent = "edit_event",
  DeleteEvent = "delete_event",
  ViewRenewals = "view_renewals",
  ViewPlacers = "view_placers",
  CreateMarket = "create_market",
  EditUser = "edit_user",
  ViewCredits = "view_credits",
  ViewCompanies = "view_companies",
  EditCompany = "edit_company",
  LinkInvoiceToCompany = "link_invoice_to_company",
  LinkInvoiceToMerchant = "link_invoice_to_merchant",
  LinkCandidacyToMerchant = "link_candidacy_to_merchant",
  LinkSubscriptionToMerchant = "link_subscription_to_merchant",
  CreateCandidacy = "create_candidacy",
  EditCandidacy = "edit_candidacy",
  DeleteCandidacy = "delete_candidacy",
  ViewCandidacy = "view_candidacy",
  GenerateRenewal = "generate_renewal",
  ReportAbsences = "report_absences",
  EditStatusSubscription = "edit_status_subscription",
  CreateUser = "create_user",
  DeleteUser = "delete_user",
  AssignRoleToUser = "assign_role_to_user",
  CreatePlacer = "create_placer",
  DeletePlacer = "delete_placer",
  LinkMarketMakingToPlacer = "link_market_making_to_placer",
  LinkDeposit = "link_deposit",
  ViewOrDownloadOwnInvoice = "view_or_download_own_invoice",
  ViewOrDownloadOwnPayment = "view_or_download_own_payment",
  ValidateDeposit = "validate_deposit",
  AssociateEvent = "associate_event",
  AssociateContact = "associate_contact",
  AssociateComment = "associate_comment",
  EditComment = "edit_comment",
  ViewComment = "view_comment",
  DeleteComment = "delete_comment",
  EditActivityHistory = "edit_activity_history",
  DeleteActivityHistory = "delete_activity_history",
  ViewActivityHistory = "view_activity_history",
  AssociateActivityHistory = "associate_activity_history",
  AssociateDocument = "associate_document",
  ArchiveDocument = "archive_document",
  DeleteMerchant = "delete_merchant",
  EditSubscription = "edit_subscription",
  DeleteSubscription = "delete_subscription",
  AssignRoleToPlacer = "assign_role_to_placer",
  EditContact = "edit_contact",
  DeleteContact = "delete_contact",
  ViewContacts = "view_contacts",
  ViewAbsences = "view_absences",
  ViewComments = "view_comments",
  CreateDocument = "create_document",
  EditDocument = "edit_document",
  DeleteDocument = "delete_document",
  ViewDocuments = "view_documents",
  AccesPlacer = "acces_placer",
  HomePlacer = "home_placer",
  ViewCashregister = "view_cashregister",
  ViewInvoicesStats = "view_invoices_stats",
  CreateMerchant = "create_merchant",
  ViewRenewalExceptions = "view_renewal_exceptions",
  ViewRenewalException = "view_renewal_exception",
  CreateRenewalException = "create_renewal_exception",
  EditRenewalException = "edit_renewal_exception",
  DeleteRenewalException = "delete_renewal_exception"
}
export default Permissions;
