enum Role {
  MANAGER = 'Gestionnaire',
  SUPER_ADMIN = 'Super-administrateur',
  ADMIN = 'Administrateur',
  CITY = 'Ville',
  PLACER = 'Placier',
  MERCHANT = 'Commerçant',
  CUSTOMER_SERVICE = 'Service-client',
  GUEST = 'Invité',
  HEAD_OF_OPERATION = 'Responsable exploitation',
  WORKER = 'Ouvrier'
}
type RoleEntity = {
  id: number;
  name: string;
  guard_name: string;
  created_at: string;
  updated_at: string;
  permissions: PermissionEntity;
}
type RoleWithPermissions = {
  id: number;
  name: string;
  permissions?: BasicPermissionInfo[]
}
type BasicPermissionInfo = Pick<PermissionEntity, 'id' | 'name'>
type PermissionEntity = {
  id: number;
  name: string;
  guard_name: string;
  created_at: string;
  updated_at: string;


}
export type { RoleWithPermissions, BasicPermissionInfo }
export default Role
