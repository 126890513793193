import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIBellIcon, LazyICheckCircle, LazyICogIcon, LazyIDasboardIcon, LazyIDocumentIcon, LazyIEditIcon, LazyIExclamationCircle, LazyILocationIcon, LazyIMoneyIcon, LazyIMoon, LazyISearch, LazyIShieldCheck, LazyIShieldExclamation, LazyIShoppingIcon, LazyISun, LazyITrashIcon, LazyIUserGroupIcon, LazyIUserIcon, LazyIViewIcon, LazyIXCircle, LazyIXMark } from '#components'
const lazyGlobalComponents = [
  ["IBellIcon", LazyIBellIcon],
["ICheckCircle", LazyICheckCircle],
["ICogIcon", LazyICogIcon],
["IDasboardIcon", LazyIDasboardIcon],
["IDocumentIcon", LazyIDocumentIcon],
["IEditIcon", LazyIEditIcon],
["IExclamationCircle", LazyIExclamationCircle],
["ILocationIcon", LazyILocationIcon],
["IMoneyIcon", LazyIMoneyIcon],
["IMoon", LazyIMoon],
["ISearch", LazyISearch],
["IShieldCheck", LazyIShieldCheck],
["IShieldExclamation", LazyIShieldExclamation],
["IShoppingIcon", LazyIShoppingIcon],
["ISun", LazyISun],
["ITrashIcon", LazyITrashIcon],
["IUserGroupIcon", LazyIUserGroupIcon],
["IUserIcon", LazyIUserIcon],
["IViewIcon", LazyIViewIcon],
["IXCircle", LazyIXCircle],
["IXMark", LazyIXMark],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
