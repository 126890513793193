import mitt from 'mitt'
import type {ToastMessageOptions} from "primevue/toast";
import type {DataTableFilterMeta} from "primevue/datatable";

type ApplicationEvents = {
    'toast': ToastMessageOptions,
    'filters:update': DataTableFilterMeta,
    'editObject': number,
    'showObject': number,
}
export default defineNuxtPlugin(() => {
    const emitter = mitt<ApplicationEvents>()

    return {
        provide: {
            eventBus: emitter
        }
    }
})
