import { default as _91id_93xkPkLkbU7qMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/companies/[id].vue?macro=true";
import { default as index20zPsn52LAMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/companies/index.vue?macro=true";
import { default as indexgdpRozTXNOMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/index.vue?macro=true";
import { default as indexopajSvhCLeMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/index.vue?macro=true";
import { default as _91id_93d6V0HDgAOMMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/show/[id].vue?macro=true";
import { default as _91id_93OtSYWE376bMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/markets/[id].vue?macro=true";
import { default as indexYbSozy0PmzMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/markets/index.vue?macro=true";
import { default as _91id_935dxZwYoz5JMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/merchants/[id].vue?macro=true";
import { default as indextY7zVo6uOuMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/merchants/index.vue?macro=true";
import { default as indexxNePEj4e1PMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/placers/index.vue?macro=true";
import { default as indexwGevaXFukuMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/renewals/index.vue?macro=true";
import { default as indexOcC1JHeJTTMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/settings/index.vue?macro=true";
import { default as securityS4hfzXKVUOMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/settings/security.vue?macro=true";
import { default as create_45editRISF6fncm3Meta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/create-edit.vue?macro=true";
import { default as index8FDeMBNIl1Meta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/index.vue?macro=true";
import { default as _91id_93JcXebKlwawMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/[id].vue?macro=true";
import { default as createdyg49niHlMMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/create.vue?macro=true";
import { default as index6tMO9596auMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/index.vue?macro=true";
import { default as showqYa1s87FZrMeta } from "/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/show.vue?macro=true";
import { default as forget_45passwordZNqDbypOUNMeta } from "/home/ploi/front.preprod.dadoun.co/pages/forget-password.vue?macro=true";
import { default as indexjp6WhtY3OMMeta } from "/home/ploi/front.preprod.dadoun.co/pages/index.vue?macro=true";
import { default as loginTWKomAnZYCMeta } from "/home/ploi/front.preprod.dadoun.co/pages/login.vue?macro=true";
import { default as _91email_930mdj0XLPKQMeta } from "/home/ploi/front.preprod.dadoun.co/pages/reset-password/[token]/[email].vue?macro=true";
import { default as indexrwt1xtE0uFMeta } from "/home/ploi/front.preprod.dadoun.co/pages/reset-password/[token]/index.vue?macro=true";
import { default as create_45edityHQriPQoOjMeta } from "~/pages/[tenant]/subscriptions/create-edit.vue?macro=true";
export default [
  {
    name: "tenant-companies-id",
    path: "/:tenant()/companies/:id()",
    meta: { ...(_91id_93xkPkLkbU7qMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: "tenant-companies",
    path: "/:tenant()/companies",
    meta: { ...(index20zPsn52LAMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "tenant",
    path: "/:tenant()",
    meta: { ...(indexgdpRozTXNOMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/index.vue").then(m => m.default || m)
  },
  {
    name: "tenant-invoices",
    path: "/:tenant()/invoices",
    meta: { ...(indexopajSvhCLeMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "tenant-invoices-show-id",
    path: "/:tenant()/invoices/show/:id()",
    meta: { ...(_91id_93d6V0HDgAOMMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/invoices/show/[id].vue").then(m => m.default || m)
  },
  {
    name: "tenant-markets-id",
    path: "/:tenant()/markets/:id()",
    meta: { ...(_91id_93OtSYWE376bMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/markets/[id].vue").then(m => m.default || m)
  },
  {
    name: "tenant-markets",
    path: "/:tenant()/markets",
    meta: { ...(indexYbSozy0PmzMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/markets/index.vue").then(m => m.default || m)
  },
  {
    name: "tenant-merchants-id",
    path: "/:tenant()/merchants/:id()",
    meta: { ...(_91id_935dxZwYoz5JMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/merchants/[id].vue").then(m => m.default || m)
  },
  {
    name: "tenant-merchants",
    path: "/:tenant()/merchants",
    meta: { ...(indextY7zVo6uOuMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/merchants/index.vue").then(m => m.default || m)
  },
  {
    name: "tenant-placers",
    path: "/:tenant()/placers",
    meta: { ...(indexxNePEj4e1PMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/placers/index.vue").then(m => m.default || m)
  },
  {
    name: "tenant-renewals",
    path: "/:tenant()/renewals",
    meta: { ...(indexwGevaXFukuMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/renewals/index.vue").then(m => m.default || m)
  },
  {
    name: "tenant-settings",
    path: "/:tenant()/settings",
    meta: { ...(indexOcC1JHeJTTMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "tenant-settings-security",
    path: "/:tenant()/settings/security",
    meta: {"middleware":["nuxt-permissions","permissions","sanctum:auth"]},
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/settings/security.vue").then(m => m.default || m)
  },
  {
    name: "tenant-subscriptions-create-edit",
    path: "/:tenant()/subscriptions/create-edit",
    meta: { ...(create_45editRISF6fncm3Meta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/create-edit.vue").then(m => m.default || m)
  },
  {
    name: "tenant-subscriptions",
    path: "/:tenant()/subscriptions",
    meta: { ...(index8FDeMBNIl1Meta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "tenant-subscriptions-renewal_exceptions-id",
    path: "/:tenant()/subscriptions/renewal_exceptions/:id()",
    meta: { ...(_91id_93JcXebKlwawMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/[id].vue").then(m => m.default || m)
  },
  {
    name: "tenant-subscriptions-renewal_exceptions-create",
    path: "/:tenant()/subscriptions/renewal_exceptions/create",
    meta: { ...(createdyg49niHlMMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/create.vue").then(m => m.default || m)
  },
  {
    name: "tenant-subscriptions-renewal_exceptions",
    path: "/:tenant()/subscriptions/renewal_exceptions",
    meta: { ...(index6tMO9596auMeta || {}), ...{"middleware":["nuxt-permissions","permissions","sanctum:auth"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/renewal_exceptions/index.vue").then(m => m.default || m)
  },
  {
    name: "tenant-subscriptions-show",
    path: "/:tenant()/subscriptions/show",
    meta: {"middleware":["nuxt-permissions","permissions","sanctum:auth"]},
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/[tenant]/subscriptions/show.vue").then(m => m.default || m)
  },
  {
    name: "forget-password",
    path: "/forget-password",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: { ...(indexjp6WhtY3OMMeta || {}), ...{"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]} },
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-token-email",
    path: "/reset-password/:token()/:email()",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/reset-password/[token]/[email].vue").then(m => m.default || m)
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: {"layout":"guest","middleware":["nuxt-permissions","permissions","sanctum:guest"]},
    component: () => import("/home/ploi/front.preprod.dadoun.co/pages/reset-password/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: "invoiceEdit",
    path: "/invoices/edit",
    meta: { ...(create_45edityHQriPQoOjMeta || {}), ...{"middleware":["sanctum:auth","nuxt-permissions"]} },
    component: () => import("~/pages/[tenant]/subscriptions/create-edit.vue").then(m => m.default || m)
  }
]