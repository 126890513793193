import Echo from 'laravel-echo';

import { WaveConnector } from 'laravel-wave';
import { useCookie } from "#app";


export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const authConfig = runtimeConfig.public.sanctum
  const tenant = useTenant();
  const authHeaders = {
    Accept: 'application/json',
    [authConfig.csrf.headerKey]: !authConfig.token
      ? useCookie(authConfig.csrf.cookieKey).value
      : null,
    'X-Tenant': tenant,
  }
  const echo = new Echo({
    broadcaster: WaveConnector,
    endpoint: `${runtimeConfig.public.sseUrl}`,
    authEndpoint: `${runtimeConfig.public.sseAuthEndpoint}`,
    request: {
      headers: authHeaders,
      credentials: "include"
    },
    forceTLS: false,
    debug: runtimeConfig.public.debugMode
  });
  return {
    provide: {
      echo: echo
    }
  }
})
  ;
