import tenantRouterOptions from "#build/tenant-router.options";
import routerOptions0 from "/home/ploi/front.preprod.dadoun.co/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...tenantRouterOptions,
...routerOptions0,
}